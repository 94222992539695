// import axios from "axios";
// import { API_BASE_URL } from "configs/AppConfig";
import { AUTH_USER } from 'constants/AuthConstant';
import fetch from 'auth/FetchInterceptor'
import { message } from "antd";
const UserService = {};

UserService.getCurrentUser = function () {
  try {
    return JSON.parse(localStorage.getItem(AUTH_USER));
  } catch (ex) {
    console.log("AUTH_USER:ERR ", ex);
    return null;
  }
};


UserService.setCurrentUser = function (data) {

  // Get current user info
  let old_user = this.getCurrentUser();
  console.log('old_user', old_user);
  // Save update user data
  let updatedUser = JSON.parse(data);
  console.log('updatedUser', updatedUser);

  // If is update for user info and passowrd not changed
  if (old_user && updatedUser.accessToken === "") {
    updatedUser = { ...updatedUser, accessToken: old_user.accessToken };
  } else if (updatedUser.accessToken ) {
    message.warning("Redirect to login in 1s");
    setTimeout(()=>{
      localStorage.removeItem(AUTH_USER);
      window?.location.reload()
    }, 1000)
  }
  localStorage.setItem(AUTH_USER, JSON.stringify(updatedUser));
};

//-----------Profile Management----------//

UserService.getPersonalInfo = async function (params) {
  return fetch({
    url: '/user/info',
    method: 'get',
    params
  })
}

UserService.updatePersonalInfo = async function (data) {
  return fetch({
    url: '/user/edit',
    method: 'post',
    data
  }
  );
};


UserService.sendBuyForm = async function (data) {
  return fetch({
    url: '/user/buy',
    method: 'post',
    data
  }
  );
};


export default UserService;