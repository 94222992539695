import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'privacy',
        path: `${AUTH_PREFIX_PATH}/privacy`,
        component: React.lazy(() => import('views/app-views/privacy')),
    },
    {
        key: 'terms',
        path: `${AUTH_PREFIX_PATH}/terms`,
        component: React.lazy(() => import('views/app-views/terms')),
    }
]

export const protectedRoutes = [
    {
        key: 'account',
        path: `${APP_PREFIX_PATH}/account`,
        component: React.lazy(() => import('views/app-views/setting')),
    },
    {
        key: 'privacy',
        path: `${APP_PREFIX_PATH}/privacy`,
        component: React.lazy(() => import('views/app-views/privacy')),
    },
    {
        key: 'terms',
        path: `${APP_PREFIX_PATH}/terms`,
        component: React.lazy(() => import('views/app-views/terms')),
    }
]
